import 'lazysizes';
import { Route, Routes } from 'react-router';
import { useEffect, useState } from 'react';
import { lazyImport } from './utils/lazyImport';
import api from './api';
import { AppRoutes } from './const';
import './App.scss'

const { OffRoad } = lazyImport(
  () => import('./Pages/OffRoad/OffRoad'),
  'OffRoad'
);
const { FestivalScreen } = lazyImport(
  () => import('./Pages/FestivalScreen/FestivalScreen'),
  'FestivalScreen'
);
const { CyclingScreen } = lazyImport(
  () => import('./Pages/CyclingScreen/CyclingScreen'),
  'CyclingScreen'
);
const { MainScreen } = lazyImport(
  () => import('./Pages/MainScreen/MainScreen'),
  'MainScreen'
);
const { CriteriumScreen } = lazyImport(
  () => import('./Pages/CriteriumScreen/CriteriumScreen'),
  'CriteriumScreen'
);

function App() {
  const [festivalCities, setFestivalCities] = useState([]);
  const [cyclingCities, setCyclingCities] = useState([]);
  const [offRoadCities, setOffRoadCities] = useState([]);
  const [criteriumCities, setCriteriumCities] = useState([]);
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    api.get('/api/event_city/type/lastrada').then((r) => {
      if (r.status === 200) {
        let festivalCities = [];
        let cyclingCities = [];
        let offRoadCities = [];
        let criteriumCities = [];

        r?.data?.values.forEach((item) => {
          if (item.event_public_id.includes('fest')) {
            festivalCities.push(item);
          } else if (item.event_public_id.includes('offroad')) {
            offRoadCities.push(item);
          } else if (item.event_public_id.includes('criterium')) {
            criteriumCities.push(item);
          } else {
            cyclingCities.push(item);
          }
        });

        setFestivalCities(festivalCities);
        setCyclingCities(cyclingCities);
        setOffRoadCities(offRoadCities);
        setCriteriumCities(criteriumCities);

        const unitedCities = [festivalCities, cyclingCities, offRoadCities, criteriumCities]

        if (unitedCities.some(arr => arr.length === 0)) {
          fetchBanners().then((r) => {
            if (r?.status === 200 && r?.data?.banners?.length > 0) {
              setBanners(r.data.banners);
            }
          })
        }
      }
    });
  }, []);

  const fetchBanners = async () => {
    try {
      return await api.get(`api/event_city/banners/lastrada`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Routes>
      <Route
        path="/"
        element={
          <MainScreen
            festivalCities={festivalCities}
            cyclingCities={cyclingCities}
            offRoadCities={offRoadCities}
            criteriumCities={criteriumCities}
          />
        }
      />
      <Route
        path={AppRoutes.CYCLING_RUS}
        element={<CyclingScreen cities={cyclingCities} banners={banners} />}
      />
      <Route
        path={AppRoutes.FESTIVAL_RUS}
        element={<FestivalScreen cities={festivalCities} banners={banners} />}
      />
      <Route
        path={AppRoutes.OFFROAD_RUS}
        element={<OffRoad cities={offRoadCities} banners={banners} />}
      />
      <Route
        path={AppRoutes.CRITERIUM_RUS}
        element={<CriteriumScreen cities={criteriumCities} banners={banners} />}
      />
      <Route
        path={AppRoutes.CYCLING}
        element={<CyclingScreen cities={cyclingCities} banners={banners} />}
      />
      <Route
        path={AppRoutes.FESTIVAL}
        element={<FestivalScreen cities={festivalCities} banners={banners} />}
      />
      <Route
        path={AppRoutes.OFFROAD}
        element={<OffRoad cities={offRoadCities} banners={banners} />}
      />
      <Route
        path={AppRoutes.CRITERIUM}
        element={<CriteriumScreen cities={criteriumCities} banners={banners} />}
      />
    </Routes>
  );
}

export default App;

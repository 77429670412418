export const AppRoutes = {
  CYCLING: '/cycling',
  FESTIVAL: '/festival',
  OFFROAD: '/offroad',
  CRITERIUM: '/criterium',
  CYCLING_RUS: '/велогонка',
  FESTIVAL_RUS: '/велофестиваль',
  OFFROAD_RUS: '/оффроуд',
  CRITERIUM_RUS: '/критериум',
}
